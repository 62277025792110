@tailwind base;
@tailwind components;
@tailwind utilities;

.site-drawer-render-in-current-wrapper {
    position: relative;
    overflow: hidden;
}

.ant-select-dropdown .ant-select-item {
    font-size: 11px !important;
}

.ant-tabs-dione .ant-tabs .ant-tabs-tab {
    padding: 1px 0 12px 0 !important;
}

.ant-table-dione .ant-table-thead .ant-table-cell {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    font-weight: 600 !important;
    --tw-text-opacity: 1 !important;
    color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.ant-table-dione .ant-table-tbody .ant-table-cell {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}

.ant-table-rowsel-dione .ant-table-body>table>tbody>tr>td:nth-of-type(1) {
    padding: 4px 8px !important;
}

.ant-table-rowsel-dione .ant-table-body>table>tbody>tr:nth-of-type(1)>td:nth-of-type(1) {
    padding: 0px !important;
}