.micpe-form .ant-form-item {
    margin-bottom: 12px !important;
}

.micpe-form .ant-form-item .ant-form-item-explain-error {
    font-size: 12px !important;
}

.micpe-form .ant-form-item .ant-form-item-label {
    padding: 0 0 4px !important;
}